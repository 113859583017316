<template>
    <div class="zt-page-content">
        
        <div class="zt-block" style="height: calc(100% - 40px);padding:20px">
            <div class="flex" style="padding-bottom:10px">
                <div class="flex-1 flex">
                    <div class="select-wrap flex flex-align-center">
                        <label>合同类型</label>
                        <el-select v-model="contractType" @change="contract_type" :popper-append-to-body="false" placeholder="全部" size="small">
                            <el-option v-for="item in config_type" :key="item.id"
                            :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select-wrap flex flex-align-center">
                        <label>状态</label>
                        <el-select v-model="contractStat" @change="contract_stat" :popper-append-to-body="false" placeholder="全部" size="small">
                            <el-option v-for="item in config_stat" :key="item.id"
                            :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="input-block flex">
                    <el-input v-model="searchValue" placeholder="合同名称查询关键字"
                        prefix-icon="el-icon-search" size="small"></el-input>
                    <el-button @click="search" type="primary" size="small">搜索</el-button>
                </div>
            </div>
            <div class="list" style="height: calc(100% - 84px);overflow-y: auto">
                <el-table :data="list" style="width:100%">
                    <el-table-column prop="id" label="序号">
                    </el-table-column>
                    <el-table-column prop="title" label="合同名">
                    </el-table-column>
                    <el-table-column prop="member_id" label="合同类型" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.contract_type==1">设计作品版权出售合同</span>
                            <span v-if="scope.row.contract_type==2">商品打样合同</span>
                            <span v-if="scope.row.contract_type==3">需求定制合同</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="对方公司" width="150">
                        <template slot-scope="scope">
                            <div v-if="comp_type==1">{{scope.row.b_sign_by_comp_name}}</div>
                            <div v-if="comp_type==2">{{scope.row.a_sign_by_comp_name}}</div>
                            <div v-if="comp_type==3">{{scope.row.b_sign_by_comp_name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="当前状态">
                        <template slot-scope="scope">
                            <div v-if="comp_type==1 || comp_type==3">
                                <div v-if="scope.row.stat==1">待我方签约</div>
                                <div v-if="scope.row.stat==2">我方已签约</div>
                                <div v-if="scope.row.stat==4">对方已签约</div>
                            </div>
                            <div v-if="comp_type==2">
                                <div v-if="scope.row.stat==3">待我方签约</div>
                                <div v-if="scope.row.stat==4">我方已签约</div>
                                <div v-if="scope.row.stat==2">对方已签约</div>
                            </div>
                            <div v-if="scope.row.stat>=9">双方已签约</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="签署人">
                        <template slot-scope="scope">
                            <div v-if="comp_type==1">{{scope.row.a_sign_by_name}}</div>
                            <div v-if="comp_type==2">{{scope.row.b_sign_by_name}}</div>
                            <div v-if="comp_type==3">{{scope.row.a_sign_by_name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="发起时间">
                        <template slot-scope="scope">
                            <div>{{scope.row.created_at | moment("YYYY/MM/DD")}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作">
                        <template slot-scope="scope">
                            <el-button @click="toView(scope.row)" type="text" size="small">查看合同</el-button>
                            <el-button @click="toDownload(scope.row)" type="text" size="small">下载合同</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:10px 0;">
                <el-pagination background @current-change="currentChange"
                    layout="prev, pager, next"
                    :current-page.sync="opt.page"
                    :page-size="opt.pagesize"
                    :total="total"></el-pagination>
            </div>
        </div>

    </div>
</template>
<script>
import { listHt } from "@/service/contract"
export default {
    data() {
        return {
            config_type:[
                {id: null, name: "全部"},
                {id: 1, name: "设计作品版权出售合同"},
                {id: 2, name: "打样合同"},
                {id: 3, name: "需求定制合同"},
            ],
            config_stat:[
                {id: null, name: "全部"},
                {id: 1, name: "待我方签约"},
                {id: 2, name: "我方已签约"},
                {id: 3, name: "对方已签约"},
                {id: 4, name: "双方已签约"},
            ],
            contractType:null,
            contractStat:null,
            searchValue:null,
            opt:{
                k: null,
                contract_type: [], //合同类型:1设计作品版权出售合同, 2打样合同, 3需求定制合同
                contract_stat: [], //合同状态:合同状态, 0待生成合同, 1待甲方签约, 2甲方签约完成, 3待乙方签约, 4乙方签约完成, 5待丙方签约, 6丙方签约完成, 7待丁方签约, 8丁方签约完成, 9合同已完成, 10已合同生成, 11合同已归档
                page: 1,
                pagesize: 15,
            },
            total:15,
            list:[], // 印章持有人列表
            comp_type: null, // 公司类型
        }
    },
    created() {
        this.comp_type = this.$store.state.loginUser.comp.comp_type;
        this.refreshItems();
    },
    mounted() {},
    methods:{
        contract_type(e) { // 筛选合同类型
            if (e==null) {
                this.opt.contract_type = [];
            } else {
                this.opt.contract_type = [e];
            }
            this.refreshItems();
        },
        contract_stat(e) { // 筛选合同状态
            if (e==null) {
                this.opt.contract_stat = [];
            } else if (e==1) {
                if (this.comp_type==1 || this.comp_type==3) {
                    this.opt.contract_stat = [1];
                } else {
                    this.opt.contract_stat = [3];
                }
            } else if (e==2) {
                if (this.comp_type==1 || this.comp_type==3) {
                    this.opt.contract_stat = [2];
                } else {
                    this.opt.contract_stat = [4];
                }
            } else if (e==3) {
                if (this.comp_type==1 || this.comp_type==3) {
                    this.opt.contract_stat = [4];
                } else {
                    this.opt.contract_stat = [2];
                }
            } else if (e==4) {
                this.opt.contract_stat = [9,11];
            }
            this.refreshItems();
        },
        search() { // 搜索
            this.opt.k = this.searchValue;
            this.refreshItems();
        },
        currentChange(e) {
            this.opt.page = e;
            this.refreshItems();
        },
        refreshItems() {
            console.log('refreshItems');
            listHt(this.opt).then(rst => {
                this.list = rst.list;
                this.total = rst.total;
                console.log(this.list);
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
            });
        },
        toView(item) {
            window.open(item.view_url);
        },
        toDownload(item) {
            location.href = item.download_url;
        }
        
    }
}
</script>
<style scoped lang="less">
    .select-wrap {
        margin-right: 30px;
        label {
            font-size: 14px;
            color: #292929;
            margin-right: 15px;
        }
    }
    .input-block {
        flex-wrap: nowrap;
        .el-input {
            max-width:200px;
        }
        .el-button {
            width: 76px;
            left: -4px;
            position: relative;
            z-index: 2;
        }
    }
</style>